import { Box, Container } from '@mui/material';
// New components need to be added here
import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import AcosContent from '../components/acos-content';
import ProgrammingAssignmentPool from '../components/assignment/programming/programming-assignment-pool';
import InfiniteProgrammingAssignment from '../components/assignment/programming/infinite-programming-assignment';
import Quiz from '../components/assignment/quiz/quiz';
import CodePuzzle from '../components/assignment/code-puzzles/code-puzzle';
import Dartpad from '../components/code-editor/dartpad';
import CodeExplanation from '../components/code-explanation';
import LLMCodeExplanation from '../components/code-explanations/llm-code-explanation';
import CreditApplicationGuidelines from '../components/credits/credit-application-guidelines';
import UserUuidCode from '../components/credits/user-uuid-code';
import Experiment from '../components/experiments/experiment';
import CodeRecallExperiment from '../components/experiments/code-recall/code-recall-experiment';
import RandomizedExperiment from '../components/experiments/randomized-experiments/randomized-experiment';
import RandomizedExperimentGroup from '../components/experiments/randomized-experiments/randomized-experiment-group';
import RestrictedByCourseProgress from '../components/points/restricted-by-course-progress';
import FeedbackForm from '../components/feedback/feedback-form';
import Footer from '../components/footer';
import ResetPasswordForm from '../components/forms/reset-password-form';
import GuessingGame from '../components/guessing-game';
import HtmlBrowserView from '../components/html-browser-view';
import HtmlTreeView from '../components/html-tree-view';
import InfoBox from '../components/info-box';
import InformationContentShuffler from '../components/information/information-content-shuffler';
import InformationIntegerToBinaryToChar from '../components/information/information-int-to-binary-to-char';
import InformationStringBinaryTable from '../components/information/information-string-binary-table';
import LearningObjectives from '../components/learning-objectives';
import UserProfileOptions from '../components/profile/user-profile-options';
import ProgramOutput from '../components/program-output';
import { H1, H2, H3 } from '../components/text-components';
import Todo from '../components/todo';
import Video from '../components/video';
import PlatformStatistics from '../components/statistics/platform-statistics';
import PeerReviewViewer from '../components/peer-review/student/peer-review-viewer';
import Mermaid from '../components/graphs/mermaid';
import useAppBarHeight from '../hooks/useAppBarHeight';
import CodePlayback from '../components/code-playback/code-playback';

const shortcodes = {
    h1: H1,
    h2: H2,
    h3: H3,
    ProgrammingAssignmentPool,
    InfiniteProgrammingAssignment,
    ProgramOutput,
    Todo,
    InformationContentShuffler,
    InformationStringBinaryTable,
    InformationIntegerToBinaryToChar,
    HtmlBrowserView,
    HtmlTreeView,
    LearningObjectives,
    Dartpad,
    InfoBox,
    Quiz,
    CodePuzzle,
    CodeExplanation,
    LLMCodeExplanation,
    Video,
    CreditApplicationGuidelines,
    UserUuidCode,
    UserProfileOptions,
    FeedbackForm,
    GuessingGame,
    AcosContent,
    ResetPasswordForm,
    Experiment,
    CodeRecallExperiment,
    RandomizedExperiment,
    RandomizedExperimentGroup,
    RestrictedByCourseProgress,
    PlatformStatistics,
    PeerReviewViewer,
    Mermaid,
    CodePlayback
};

/*
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            minHeight: 'calc(100vh - 64px)', // compensate for toolbar height
            [theme.breakpoints.down('lg')]: {
                minHeight: 'calc(100vh - 56px)', // compensate for toolbar height
            },
        },
    }),
);
*/

export default function PageTemplate({ data, ...props }) {
    const appBarHeight = useAppBarHeight();
    return (
        <Box
            component={'main'}
            id="skip-nav-target"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: (theme) => `calc(100vh - ${appBarHeight}px)`,
            }}
        >
            <Container maxWidth="md" style={{ flex: '1 1 auto' }}>
                <H1>{data.mdx.frontmatter.title}</H1>
                <Box mt={5} mb={8}>
                    <MDXProvider components={shortcodes}>
                        <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    </MDXProvider>
                </Box>
            </Container>
            <Footer {...props} />
        </Box>
    );
}

export const pageQuery = graphql`
    query GenericContentPageQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            frontmatter {
                title
                published
            }
        }
    }
`;
